import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import Imprint from './Imprint';

export default props => {
  const data = {
    imprint: props.data.imprint.nodes[0],
  };
  return (
    <Layout>
      <Imprint {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    imprint: allContentfulImprintPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        textBlocksWithHeadline {
          headline
          textContent {
            json
          }
        }
      }
    }
  }
`;
