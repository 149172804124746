import React from 'react';
import {
  DetailGrid,
  Carousel,
  CarouselScreen,
  Footer,
  RichTextRenderer,
  FullViewportStage,
} from '../../components';

class Imprint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data.imprint,
    };
  }

  render() {
    const { location } = this.props.location;
    const { data } = this.state;
    const { title, textBlocksWithHeadline } = data[0] || data;
    return (
      <div>
        <Carousel location={location}>
          <CarouselScreen scrollable={true}>
            <FullViewportStage regularOverflow spacedTopBottom>
              <h2
                className={
                  'typography__header4 typography__header4--spaceBottom'
                }
              >
                {title}
              </h2>
              {textBlocksWithHeadline.map((el, idx) => (
                <DetailGrid.Container
                  key={`${el.headline}-${idx}`}
                  label={el.headline}
                  bottom={
                    <DetailGrid.Cell>
                      <RichTextRenderer
                        content={el.textContent.json}
                        limitedWidth
                      />
                    </DetailGrid.Cell>
                  }
                />
              ))}
              <Footer />
            </FullViewportStage>
          </CarouselScreen>
        </Carousel>
      </div>
    );
  }
}

export default Imprint;
